import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet negre o blau negrós amb un capell de fins a 8 cm de diàmetre que amb el temps passa de forma plana a forma d’embut. La superfície és més o menys zonada i amb esquametes carnoses. El marge és fi i de color blanquinós a blau. Davall el capell està format per agulletes decurrents, blanques al principi o un poc blauoses que després tornen color gris marró. El peu és de cilíndric a irregular de color negrós. Les espores són marró ocraci, quasi rodones, de 3-5 x 2-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      